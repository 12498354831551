import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { Grid } from 'semantic-ui-react';

import SeriesCarousel from 'src/components/shared/SeriesCarousel';
import VideoPlayer from 'src/components/shared/VideoPlayer';
import SEO from 'src/components/SEO';

const SeriesDetailsPageTemplate = ({ data }) => {
    console.log({ data });
    const {
        contentfulSeries: {
            benefit: benefits,
            bodyFocus: bodyFocuses,
            childContentfulSeriesDescriptionTextNode: seriesDescription,
            contentful_id: seriesId,
            equipment: equipments,
            instructor: instructors,
            length: seriesLength,
            name: seriesName,
            thumbnail: {
                fixed: { srcWebp: seriesThumbnail },
            },
            workout: seriesWorkouts,
        },
    } = data;

    const [videoSrc, setVideoSrc] = useState();

    const openVideoPlayer = (source) => {
        setVideoSrc(source);
    };

    const closeVideoPlayer = () => {
        setVideoSrc(null);
    };

    const playerOptions = {
        /**
         * Optional: Any additional VideoJs options
         * Will override defaults in VideoPlayer.js
         */
    };

    /**
     * Optional: any additional event handling
     * Event analytics are handled inside VideoPlayer.js
     *
     * In this example, we are using `onClose`
     * to update page-level video state.
     */
    const playerEvents = {
        onClose: closeVideoPlayer,
        onPlay: () => console.log('onPlay'),
        onPause: () => console.log('onPause'),
    };

    return (
        <div>
            <SEO title={`Series: ${seriesName}`} />
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <SeriesCarousel
                            series={data.contentfulSeries}
                            onVideoClick={openVideoPlayer}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <VideoPlayer
                src={videoSrc}
                options={playerOptions}
                events={playerEvents}
            />
        </div>
    );
};

export const query = graphql`
    query SeriesDetailPageQuery($contentful_id: String!) {
        contentfulSeries(contentful_id: { eq: $contentful_id }) {
            name
            length
            instructor {
                name
            }
            equipment {
                name
            }
            bodyFocus {
                name
            }
            benefit {
                name
            }
            childContentfulSeriesDescriptionTextNode {
                description
            }
            thumbnail {
                fixed {
                    srcWebp
                }
            }
            workout {
                contentful_id
                name
                length
                thumbnail {
                    fixed {
                        srcWebp
                    }
                }
                equipment {
                    name
                }
                bodyFocus {
                    name
                }
            }
        }
    }
`;

export default SeriesDetailsPageTemplate;
